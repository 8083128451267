export const middlePhone =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692283521/500chow%20public/chowcentral%20website/middle-phone_e3zgfj.png";
export const leftPhone =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692282999/500chow%20public/chowcentral%20website/left-phone_byxxtw.png";
export const rightPhone =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692283519/500chow%20public/chowcentral%20website/right-phone_ebxys8.png";

export const carousel1 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692283499/500chow%20public/chowcentral%20website/carousel1_n7wf86.png";
export const carousel2 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692282996/500chow%20public/chowcentral%20website/iphone-payments_omlvuo.png";
export const carousel3 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692283035/500chow%20public/chowcentral%20website/iphone-track_walthv.png";
export const carousel4 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692283501/500chow%20public/chowcentral%20website/carousel4_hsydbv.png";

export const best =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692282988/500chow%20public/chowcentral%20website/best_wdrkbl.png";
export const share =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692282990/500chow%20public/chowcentral%20website/share_bygt3c.png";
export const work =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692282991/500chow%20public/chowcentral%20website/work_fewouf.png";
export const party =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692282991/500chow%20public/chowcentral%20website/party_lwjkgv.png";

export const bottomBanner1 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692282995/500chow%20public/chowcentral%20website/bottom-banner1_oejdcj.png";
export const bottomBanner2 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692283032/500chow%20public/chowcentral%20website/bottom-banner2_vw7guw.png";
export const bottomBanner3 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692283016/500chow%20public/chowcentral%20website/bottom-banner3_kxtr3k.png";
export const bottomBanner4 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692282998/500chow%20public/chowcentral%20website/bottom-banner4_dzolqo.png";
export const bottomBanner5 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692282995/500chow%20public/chowcentral%20website/bottom-banner5_bykqpz.png";

export const company1 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692283278/500chow%20public/chowcentral%20website/company-hero-1_bqxxon.png";
export const company2 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692283499/500chow%20public/chowcentral%20website/company-hero-2_tqesvq.png";
export const company3 =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692283501/500chow%20public/chowcentral%20website/company-hero-3_v0wvyn.png";

export const chowBackground =
  "https://res.cloudinary.com/five-hundred-chow/image/upload/f_auto,q_auto/v1676541849/food/chow-bg_nayxur.png";

export const logo =
  "https://res.cloudinary.com/five-hundred-chow/image/upload/f_auto,q_auto/v1671973922/logos/chow-central-logo-2-1_gotowl.png";

export const phone1 =
  "https://res.cloudinary.com/five-hundred-chow/image/upload/f_auto,q_auto/v1680956300/assets/phone1_lgkmir.png";

export const phone2 =
  "https://res.cloudinary.com/five-hundred-chow/image/upload/f_auto,q_auto/v1680957577/assets/phone_2_kmzosl.png";

export const damilolaTweet =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692285286/500chow%20public/chowcentral%20website/tweets/damilola_hdppr1.jpg";

export const bigMamaTweet =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692285378/500chow%20public/chowcentral%20website/tweets/big-mama_vc2bq7.jpg";

export const temiTweet =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692285597/500chow%20public/chowcentral%20website/tweets/temi_pi1dvy.jpg";

export const chigozieTweet =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692286129/500chow%20public/chowcentral%20website/tweets/chigozie_xulhul.jpg";

export const metafiosoTweet =
  "https://res.cloudinary.com/okekeobasi/image/upload/v1692286126/500chow%20public/chowcentral%20website/tweets/metafioso_lwz17w.jpg";
