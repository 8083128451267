import {
  craveSticker,
  deliverySticker,
  demandSticker,
  faqsBannerOverlay,
  faqsBannerOverlay2,
  rotateSticker,
  sticker11,
  sticker8,
} from "../../assets/svg";
import { faqs } from "../../constants";
import { FaqCard } from "../components";

const FAQs = () => {
  return (
    <>
      <div
        className={`h-[430px] sm:h-[300px] flex items-center justify-center mx-2.5 sm:mx-5 mt-2.5 sm:mt-5 lg:mt-28 rounded-[20px] relative bg-chow-red overflow-hidden`}
      >
        <img
          src={faqsBannerOverlay}
          alt="banner-overlay"
          className="absolute w-full top-0"
        />
        <img
          src={faqsBannerOverlay2}
          alt="banner-overlay"
          className="absolute w-full bottom-0"
        />

        <img
          src={deliverySticker}
          alt="delivery-sticker"
          className="w-[90px] xl:w-[230px] absolute left-0 bottom-0 z-10"
        />
        <img
          src={sticker8}
          alt="sticker8"
          className="w-[90px] xl:w-[200px] absolute left-40 xl:left-52 -bottom-4 xl:bottom-5"
        />
        <img
          src={demandSticker}
          alt="demand-sticker"
          className="w-[110px] xl:w-[250px] absolute left-12 xl:left-32 -bottom-5 xl:bottom-28 z-10"
        />

        <img
          src={craveSticker}
          alt="travel-sticker"
          className="w-[110px] xl:w-[250px] absolute right-10 xl:right-72 -bottom-10 xl:bottom-0 z-10"
        />
        <img
          src={sticker11}
          alt="sticker-11"
          className="w-[110px] xl:w-[250px] absolute right-0 xl:right-32 bottom-0 z-10"
        />
        <img
          src={rotateSticker}
          alt="rotate-sticker"
          className="w-[90px] xl:w-[200px] absolute right-5 xl:right-52 bottom-9 xl:bottom-20 z-10 rotating-element"
        />

        <p className="text-3xl lg:text-6xl medium text-center text-white relative z-10">
          Frequently Asked <br />
          Questions
        </p>
      </div>

      {/* faqs */}
      <div className="pt-12 sm:pt-36 pb-52 px-8 sm:px-10 lg:px-32">
        <div className="mt-10 lg:mt-16">
          <div className="sm:w-full xl:w-7/12 mx-auto divide-y border-t-[0.5px] border-b-[0.5px] border-black border-opacity-25">
            {faqs.map((faq, index) => (
              <FaqCard key={faq.title + index} {...faq} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQs;
