import React from "react";
import { downloadApp } from "../../helpers";

const Download = () => {
  React.useEffect(() => {
    downloadApp();
  }, []);
  return <></>;
};
export default Download;
