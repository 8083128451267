import {
  BrandCard,
  CarouselCard,
  FaqCard,
  Hero,
  TwitterCard,
} from "../components";
import "../../theme/global.scss";
import React from "react";
import { IBrand } from "../../types/app.types";
import { PreFooter } from "../components/pre-footer";
import { bannerMotif } from "../../assets/svg";
import { best, party, share, work } from "../../assets/png";
import { useNavigate } from "react-router-dom";
import { carouselCopies, carouselCopies2, faqs, tweets } from "../../constants";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "aos/dist/aos.css";
import Aos from "aos";

// @ts-ignore
import Marquee from "react-double-marquee";

gsap.registerPlugin(ScrollTrigger);

const Home = ({ brands }: { brands: IBrand[] }) => {
  const navigate = useNavigate();

  const slider = React.useRef<HTMLDivElement>(null);
  const container = React.useRef<HTMLDivElement>(null);

  // horizontal scrolling stuff
  React.useLayoutEffect(() => {
    let mm = gsap.matchMedia();

    let ctx = gsap.context(() => {
      let panels = gsap.utils.toArray(".panel");

      mm.add("(min-width: 640px)", () => {
        gsap.to(panels, {
          xPercent: -100 * (panels.length - 1),
          ease: "none",
          scrollTrigger: {
            trigger: slider.current,
            pin: true,
            start: "top 15%",
            scrub: 1,
            end: () => "+=" + slider.current!.offsetWidth,
            markers: false,
          },
        });
      });
    }, container);

    return () => ctx.revert();
  });

  React.useEffect(() => {
    Aos.init({ duration: 40000 });
  }, []);

  return (
    <div ref={container}>
      <Hero />

      <div className="py-14 bg-cream2 bg-opacity-50 mx-2.5 sm:mx-5 mt-5 rounded-[20px]">
        <div className="w-9/12 sm:w-7/12 xl:w-4/12 mx-auto flex flex-col items-center">
          <p className="text-2xl sm:text-5xl bold text-black text-center">
            Your favourite food brands are here!
          </p>
          <div className="px-4 py-2.5 mt-6 bg-white rounded-full text-center text-xs sm:text-base">
            Coming soon to a city near you
          </div>
        </div>
      </div>

      {/* brands */}
      <div
        id="brands"
        className="grid grid-cols-1 lg:grid-cols-2 gap-[18px] mx-2.5 sm:mx-5 mt-5"
      >
        {brands.slice(0, 2).map((brand) => (
          <BrandCard key={brand.id} {...brand} />
        ))}
      </div>

      {/* carousel */}
      <div
        className="relative mt-5 flex flex-col sm:flex-row mx-2.5 sm:mx-0 overflow-x-hidden"
        ref={slider}
      >
        <div
          className={`grid grid-cols-1 sm:grid-cols-2 gap-[18px] mx-auto w-full flex-shrink-0 sm:mx-5`}
        >
          {carouselCopies.map((card) => (
            <CarouselCard key={card.title} {...card} />
          ))}
        </div>
        <div
          className={`grid grid-cols-1 sm:grid-cols-2 gap-[18px] w-full mx-auto mt-5 sm:m-0 flex-shrink-0`}
        >
          {carouselCopies2.map((card) => (
            <CarouselCard key={card.title} {...card} />
          ))}
        </div>
      </div>

      <div className="mt-5 mx-2.5 sm:mx-5 bg-chow-red relative rounded-[20px] h-[375px] sm:h-[800px] flex justify-center items-center">
        <img
          src={bannerMotif}
          alt="overlay"
          className="absolute top-0 left-0 w-full h-full"
        />

        <div className="text-xl sm:text-4xl lg:text-6xl text-white relative z-10 space-y-2 sm:space-y-6">
          <div className="flex items-center">
            <img
              src={best}
              alt="best-png"
              className="h-6 w-8 sm:w-auto object-cover rounded-full sm:h-[55px] mr-1 sm:mr-4"
            />
            <p className="bold" data-aos="fade-up">
              Best meals with ChowCentral
            </p>
          </div>
          <div className="flex items-center ml-10 lg:ml-20" data-aos="fade-up">
            <p className="bold">Share with</p>
            <img
              src={share}
              alt="best-png"
              className="h-6 w-8 sm:w-auto object-cover rounded-full sm:h-[55px] mx-1 sm:mx-4"
            />
            <p className="bold">ChowCentral</p>
          </div>
          <div className="flex items-center ml-10 lg:ml-20" data-aos="fade-up">
            <p className="bold">Work with ChowCentral</p>
            <img
              src={work}
              alt="best-png"
              className="h-6 w-8 sm:w-auto object-cover rounded-full sm:h-[55px] mx-1 sm:mx-4"
            />
          </div>
          <div className="flex items-center ml-10 lg:ml-20" data-aos="fade-up">
            <p className="bold">Party</p>
            <img
              src={party}
              alt="best-png"
              className="h-6 w-8 sm:w-auto object-cover rounded-full sm:h-[55px] mx-1 sm:mx-4"
            />
            <p className="bold">with ChowCentral</p>
          </div>
        </div>
      </div>

      {/* twitter mentions */}
      <div className="mt-5 mx-2.5 sm:mx-5 bg-cream2 bg-opacity-30 relative rounded-[20px] py-32">
        <p className="text-2xl sm:text-5xl text-center bold mx-auto w-11/12">
          What our Customers are Saying
        </p>

        <div className="mt-16 marquee-parent">
          <Marquee direction="left">
            <div className="flex py-5">
              {tweets
                .slice(0, 3)
                .reverse()
                .map((tweet) => (
                  <TwitterCard key={tweet.tweet} {...tweet} />
                ))}
            </div>
          </Marquee>

          <Marquee direction="right">
            <div className="flex py-5">
              {tweets.slice(3, 6).map((tweet) => (
                <TwitterCard key={tweet.tweet} {...tweet} />
              ))}
            </div>
          </Marquee>
        </div>

        <div className="marquee-parent sm:hidden">
          <Marquee direction="left">
            <div className="flex py-5">
              {tweets.map((tweet) => (
                <TwitterCard key={tweet.tweet} {...tweet} />
              ))}
            </div>
          </Marquee>
        </div>
      </div>

      {/* faqs */}
      <div className="pt-16 sm:pt-36 pb-52 px-10 lg:px-32">
        <h2 className="text-chow-red medium text-4xl sm:text-6xl text-center">
          FAQs
        </h2>
        <div className="mt-10 lg:mt-16">
          <div className="sm:w-full xl:w-7/12 mx-auto divide-y">
            {faqs.slice(0, 3).map((faq, index) => (
              <FaqCard key={faq.title + index} {...faq} />
            ))}
          </div>

          <div className="flex justify-center mt-12">
            <button
              className="px-7 sm:px-10 py-4 sm:py-5 bg-black text-white text-lg rounded-[10px]"
              onClick={() => navigate("/faqs")}
            >
              View All
            </button>
          </div>
        </div>
      </div>

      <PreFooter />
    </div>
  );
};

export default Home;
