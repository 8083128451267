import { FC, InputHTMLAttributes, useState } from "react";
import { closedEye, openEye } from "../../assets/svg";

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const PrimaryInput: FC<IInput> = ({ label, ...rest }) => {
  const [view, setView] = useState(false);

  const toggleView = () => setView((prev) => !prev);

  return (
    <div className={`flex flex-col mt-7 relative ${rest.className}`}>
      <label
        className="medium text-sm text-neutral-900 flex justify-between items-center"
        htmlFor={rest.name}
      >
        <span>{label}</span>
        {rest.required && <span className="text-primary-red">*</span>}
      </label>

      {rest.type === "password" && (
        <button
          className="absolute bottom-5 right-5 focus:outline-none"
          onClick={toggleView}
        >
          <img src={view ? openEye : closedEye} alt="eye" className="h-4" />
        </button>
      )}

      <input
        id={rest.name}
        {...rest}
        type={rest.type === "password" && view ? "text" : rest.type}
        className={`border border-neutral-300 rounded-md px-3 py-4 shadow-xsmall focus:outline-none ${
          rest.disabled ? "cursor-not-allowed" : ""
        }`}
      />
    </div>
  );
};
