import { Link } from "react-router-dom";
import { company1, company2, company3 } from "../../assets/png";
import { craveSticker, demandSticker, rotateSticker } from "../../assets/svg";

const Company = () => {
  return (
    <>
      <div className="mx-2.5 sm:mx-5 mt-2.5 sm:mt-5 lg:mt-28 grid grid-cols-12 gap-[5px] sm:gap-[15px] content-stretch">
        <div className="col-span-12 xl:col-span-5 bg-cream2 rounded-[10px] sm:rounded-[20px] px-6 sm:px-14 pt-32 lg:pt-20 pb-6 sm:pb-24 h-auto xl:h-[720px]">
          <div className="flex">
            <img
              src={rotateSticker}
              alt="rotate-sticker"
              className="w-[110px] sm:w-[160px] rotating-element"
            />
            <img
              src={demandSticker}
              alt="demand-sticker"
              className="w-[150px] sm:w-[220px] -ml-6 relative z-10"
            />
          </div>

          <p className="mt-8 lg:mt-32 medium text-3xl sm:text-5xl">About Us</p>
          <p className="mt-2.5 sm:mt-10 sm:text-xl">
            We are an online food delivery platform committed to offering a
            convenient and dependable way for customers to order food from our
            diverse range of restaurants. With us, you can have your meals
            delivered straight to your doorstep in minutes!
          </p>
        </div>

        <div className="col-span-5 xl:col-span-3 flex flex-col h-[330px] sm:h-[720px] mt-2 sm:mt-0">
          <img
            src={company1}
            alt="company"
            className="h-1/2 object-cover rounded-[10px] sm:rounded-[20px]"
          />
          <img
            src={company2}
            alt="company"
            className="mt-1 sm:mt-3.5 h-[calc(50%-4px)] sm:h-[calc(50%-14px)] object-cover rounded-[10px] sm:rounded-[20px]"
          />
        </div>

        <div className="col-span-7 xl:col-span-4 mt-2 sm:mt-0">
          <img
            src={company3}
            alt="company"
            className="h-[330px] sm:h-[720px] w-full object-cover rounded-[10px] sm:rounded-[20px]"
          />
        </div>
      </div>

      <div className="mx-2.5 sm:mx-5 mt-2.5 sm:mt-5 bg-chow-red p-6 rounded-[10px] sm:rounded-[20px] flex flex-col xl:flex-row xl:space-x-11 overflow-hidden">
        <div className="h-[250px] sm:h-[550px] xl:h-[750px] bg-black rounded-[20px] xl:w-8/12 flex-shrink-0">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="w-full h-full object-cover rounded-[20px]"
          >
            <source
              src="https://res.cloudinary.com/okekeobasi/video/upload/v1692620425/500chow%20public/chowcentral%20website/ChowCentral_App_Launch_Video_Low_tuvf0u.mp4"
              type="video/mp4"
            />
          </video>
        </div>

        <div className="text-white flex flex-col justify-between items-center pt-16 xl:pt-4 pb-4 sm:pb-16 pr-2 relative">
          <img
            src={craveSticker}
            alt="sticker"
            className="absolute xl:static w-[103px] sm:w-[210px] xl:w-auto -right-12 sm:-right-20 top-0 sm:-top-5"
          />
          <div>
            <p className="text-3xl sm:text-5xl medium">Our Mission</p>
            <p className="mt-5 sm:mt-10 sm:text-xl">
              We are building the world's most loved, trusted, and
              fastest-growing restaurant brands in partnership with the best
              kitchen operators in the business. Our mission is to provide
              quality meals conveniently to as many people as possible though a
              unified platform.
            </p>
          </div>
        </div>
      </div>

      <div className="mt-2.5 sm:mt-5 mx-2.5 sm:mx-5 bg-cream2 flex flex-col items-center justify-center h-[280px] sm:h-[420px] rounded-[10px] sm:rounded-[20px]">
        <p className="text-3xl sm:text-6xl bold text-primary-300">Careers</p>
        <Link to="https://500chow.breezy.hr/" target="_blank">
          <button className="px-7 sm:px-10 py-3.5 sm:py-5 bg-black text-white text-sm sm:text-lg rounded-[10px] mt-3 sm:mt-12">
            See Open Positions
          </button>
        </Link>
      </div>
    </>
  );
};

export default Company;
