import { Link } from "react-router-dom";
import { creamLogo } from "../../assets/svg";
import { IBrand } from "../../types/app.types";
import { blog } from "../../constants";

export const Footer = ({ brands }: { brands: IBrand[] }) => {
  return (
    <div className="py-12 px-10 sm:px-28 lg:px-10 xl:px-28  lg:pt-36 pb-24 bg-chow-red mt-5 rounded-t-[20px]">
      <div className="flex flex-col lg:flex-row pb-28 justify-between items-start xl:w-9/12">
        <img src={creamLogo} alt="logo" />

        <div className="flex text-base sm:text-2xl justify-between w-full mt-14 lg:mt-0 lg:w-7/12">
          <div className="text-white inline-flex flex-col space-y-5">
            <Link target="_blank" to={blog}>
              Blog
            </Link>
            {brands.map((brand) => (
              <Link key={brand.id} to={`/brand/${brand?.slug}`}>
                {brand.name}
              </Link>
            ))}
          </div>
          <div className="text-white inline-flex flex-col space-y-5">
            <p className="bold">Contact</p>
            <a href="mailto:hello@chowcentral.com">hello@chowcentral.com </a>
            <a href="tel:+2349021077210">+2349021077210</a>
          </div>
        </div>
      </div>

      <div className="pt-10 flex flex-col lg:flex-row text-white border-t border-white border-opacity-50 text-xs sm:text-base">
        <p className="lg:mr-60">© 2023 ChowCentral All rights reserved.</p>
        <div className="flex items-center space-x-5">
          <Link to="/privacy-policy">
            <p className="mt-3.5 lg:mt-0">Privacy</p>
          </Link>
          <Link to="/terms-and-conditions">
            <p className="mt-3.5 lg:mt-0">Terms & Conditions</p>
          </Link>
        </div>
      </div>
    </div>
  );
};
