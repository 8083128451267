import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IBrand } from "../../types/app.types";
import { MealCard } from "../components";
import { Loader } from "../components/loader";
import { PreFooter } from "../components/pre-footer";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Brand = () => {
  const [brand, setBrand] = useState<IBrand | null>(null);
  const { slug } = useParams();

  const getBrand = useCallback(async () => {
    await fetch(`${BASE_URL}/brand/${slug}/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => setBrand(data.data))
      .catch(() => {});
  }, [slug]);

  const hiddenMeals = [
    "Fanta orange",
    "Bottled water",
    "Coca-cola",
    "Cola",
    "Water (Bottle)",
    "Fanta soda",
    "Coke",
    "Fanta",
    "Water",
    "Malt",
    "Chivita",
  ];

  useEffect(() => {
    getBrand();
    // eslint-disable-next-line
  }, [getBrand]);

  return (
    <>
      <div className="overflow-hidden mx-2.5 sm:mx-5 mt-2.5 sm:mt-5 lg:mt-28 rounded-[20px] relative h-[720px]">
        <div
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), no-repeat center / cover url(${brand?.bannerUrl})`,
          }}
          className="absolute h-full w-full"
        />
      </div>

      <div className="py-20 xl:py-36 px-5 sm:px-10 xl:px-32">
        <h2 className="medium text-3xl sm:text-6xl text-center">Menu</h2>
        <div className="mt-10 flex justify-center flex-wrap">
          {brand !== null ? (
            brand.meals
              .filter(
                (meal) => meal.imageUrl && !hiddenMeals.includes(meal.name)
              )
              .map((meal) => <MealCard key={meal.id} {...meal} />)
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <PreFooter />
    </>
  );
};
export default Brand;
