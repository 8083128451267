import { KeyboardEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logo } from '../../assets/png';
import { PrimaryButton } from '../components/buttons';
import { PrimaryInput } from '../components/inputs';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ResetPassword = () => {
  const [searchParams] = useSearchParams();

  const [payload, setPayload] = useState({
    confirmPassword: '',
    password: '',
    code: searchParams.get('code'),
  });

  const [required, setRequired] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const _handleReset = (e?: SyntheticEvent<HTMLFormElement>) => {
    setLoading(true);
    if (!required) return;
    e?.preventDefault();

    fetch(`${BASE_URL}/password/reset/`, {
      method: 'POST',

      body: JSON.stringify(payload),

      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(() => {
        toast.success('password changed successfully');
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Invalid Code');
        setLoading(false);
        throw error;
      });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _handleReset();
    }
  };

  const onGetInputValue = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setPayload({ ...payload, [name]: value });
  };

  useEffect(() => {
    const { confirmPassword, password } = payload;
    setRequired(
      confirmPassword.length > 0 &&
        password.length > 0 &&
        confirmPassword === password
    );
  }, [payload]);

  return (
    <div className="h-screen bg-grey-2 sm:py-12 lg:py-32 flex items-center justify-center">
      <form
        className="flex flex-col items-stretch justify-center bg-white rounded-xl h-full w-full sm:h-auto p-12 sm:w-8/12 lg:w-7/12 xl:w-4/12"
        onSubmit={_handleReset}
      >
        <img src={logo} alt="logo" className="h-10 w-10 mx-auto" />
        <h1 className="text-neutral-800 bold text-center text-2xl">
          Reset your password
        </h1>

        <PrimaryInput
          onChange={onGetInputValue}
          onKeyDown={handleKeyDown}
          placeholder="Enter password"
          required
          name="password"
          label="Password"
          type="password"
        />
        <PrimaryInput
          onChange={onGetInputValue}
          onKeyDown={handleKeyDown}
          placeholder="Confirm password"
          required
          name="confirmPassword"
          label="Password"
          type="password"
        />

        <PrimaryButton
          loading={loading}
          disabled={!required || loading}
          text="Reset Password"
          type="submit"
          classNames="mt-10 medium"
        />
      </form>
    </div>
  );
};

export default ResetPassword;
