import { KeyboardEvent, SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logo } from '../../assets/png';
import { PrimaryButton } from '../components/buttons';
import { PrimaryInput } from '../components/inputs';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const DeleteRequest = () => {
  const navigate = useNavigate();

  const [payload, setPayload] = useState({
    username: '',
    email: '',
    reason: '',
    phoneNumber: '',
  });

  const [loading, setLoading] = useState<boolean>(false);

  const _handleReset = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();
    console.log('reach');
    setLoading(true);

    fetch(`${BASE_URL}/user/delete-request`, {
      method: 'POST',

      body: JSON.stringify(payload),

      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(() => {
        toast.success('Delete request sent successfully');
        setLoading(false);
        navigate('/');
      })
      .catch((error) => {
        toast.error('Invalid Code');
        setLoading(false);
        throw error;
      });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _handleReset();
    }
  };

  const onGetInputValue = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setPayload({ ...payload, [name]: value });
  };

  return (
    <div className="h-screen bg-grey-2 sm:py-12 lg:py-32 flex items-center justify-center">
      <form
        className="flex flex-col items-stretch justify-center bg-white rounded-xl h-full w-full sm:h-auto p-12 sm:w-8/12 lg:w-7/12 xl:w-4/12"
        onSubmit={_handleReset}
      >
        <img src={logo} alt="logo" className="h-10 w-10 mx-auto" />
        <h1 className="text-neutral-800 bold text-center text-2xl">
          Send a Request to Delete your Account
        </h1>
        <p>
          Once you complete this form, we would reach out to complete the
          process
        </p>
        <p>
          ChowCentral does not store any sensitive information and on request
          all data will be removed from our servers
        </p>

        <PrimaryInput
          onChange={onGetInputValue}
          onKeyDown={handleKeyDown}
          placeholder="Username"
          name="username"
          label="Username"
          type="text"
          required
        />

        <PrimaryInput
          onChange={onGetInputValue}
          onKeyDown={handleKeyDown}
          placeholder="email"
          name="email"
          label="Email Address"
          type="email"
        />

        <PrimaryInput
          onChange={onGetInputValue}
          onKeyDown={handleKeyDown}
          placeholder="Phone Number"
          name="phoneNumber"
          label="Phone number"
          type="text"
          required
        />

        <PrimaryInput
          onChange={onGetInputValue}
          onKeyDown={handleKeyDown}
          placeholder="Tell us why"
          name="reason"
          label="Tell us why"
          type="text"
          required
        />

        <PrimaryButton
          loading={loading}
          disabled={loading}
          text="Submit"
          type="submit"
          classNames="mt-10 medium"
        />
      </form>
    </div>
  );
};

export default DeleteRequest;
