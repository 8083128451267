import { ButtonHTMLAttributes, FC } from "react";

interface IPrimaryButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  loading?: boolean;
  onClick?: () => void;
  classNames?: string;
  disabled?: boolean;
}

export const PrimaryButton: FC<IPrimaryButton> = ({
  text,
  loading,
  onClick,
  classNames,
  disabled,
  ...props
}) => {
  return (
    <button
      className={`rounded-[10px] py-2.5 px-6 text-white ${
        disabled ? "cursor-not-allowed bg-primary-200" : "bg-chow-red"
      } ${classNames}`}
      onClick={onClick}
      {...props}
    >
      {loading ? "Loading..." : text}
    </button>
  );
};
