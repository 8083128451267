const PrivacyPolicy = () => {
  return (
    <div className="p-8 text-sm mt-28">
      <div className="sm:w-8/12 xl:w-6/12 mx-auto mt-20">
        <h1 className="text-xl bold">Privacy Policy for Chow Central</h1>
        <br />
        500Chow Technologies Limited (“500Chow” or "Chow Central" or "we" or
        "our") is fully committed to safeguarding and respecting your privacy.
        We prioritize transparency in handling your personal information.
        Therefore, our Privacy Policy (“Privacy Policy”) is crafted to provide
        clarity on how we collect, utilize, and protect the information you
        share with us, enabling you to make informed decisions while using our
        services. Upon signing up and utilizing Chow Central (via our website or
        mobile app), you implicitly agree to the terms outlined in this Privacy
        Policy. Your personal information is kept confidential, shared only as
        described herein. For clarity, this Privacy Policy is subject to review
        under the Nigeria Data Protection Act, 2023 (NDPA) and applicable laws
        of Nigeria.
        <br />
        <br />
        <h2 className="text-xl bold">
          1. What Personal Information do we collect?
        </h2>
        <br />
        Ensuring the privacy and security of your personal information is
        paramount to us. We only collect information necessary to serve you
        effectively. Below are details on the types of personal information we
        collect and the circumstances under which it is collected (“Personal
        Information”).
        <br />
        - Customer Information: This includes full name, email address, phone
        number, Chow Central password, and address (for delivery purposes),
        submitted during registration or profile creation. Financial data,
        including bank account details (where applicable) and payment card
        information, is also collected.
        <br />
        - Location Information: Some Chow Central features may require location
        information from your device's GPS. With your consent, this information
        is collected for specific services. You will be prompted to grant
        consent, and disabling location services may affect certain services.
        <br />
        <br />
        <h2 className="text-xl bold">
          2. What Non-Personal Information do we collect?
        </h2>
        <br />
        We gather non-personal information to enhance services, understand usage
        patterns, and protect against service-related issues.
        <br />
        - Device Information: When using the Chow Central mobile app, details
        about your device, unique identifiers (IMEI or serial number), SIM card
        information, mobile network, operating system, and browser settings are
        collected but not stored.
        <br />
        - Analytics: In-app analytics technologies like Google Analytics may be
        used to improve app design, performance, and reporting on application
        failures.
        <br />
        - Tracking and Cookies: Chow Central uses cookies to identify and track
        visitors, understand website usage, and preferences. Visitors who prefer
        not to have cookies placed on their devices can adjust browser settings,
        although this may impact service functionality. We also track app
        installations and uninstalls.
        <br />
        <br />
        <h2 className="text-xl bold">
          3. How do we use the information obtained from you?
        </h2>
        <br />
        We utilize your Personal and Non-Personal Information to: a) Provide
        required services.
        <br />
        b) Respond to inquiries.
        <br />
        c) Enhance features, analyze data, and develop products and services.
        <br />
        d) Address inappropriate use of our platform.
        <br />
        e) Prevent, detect, and manage risks related to fraud and illegal
        activities.
        <br />
        f) Send marketing content, newsletters, and service updates with your
        explicit consent.
        <br />
        g) Verify identity and information in line with statutory obligations.
        <br />
        h) Maintain up-to-date records.
        <br />
        i) Resolve disputes, including those involving law enforcement or
        regulatory bodies.
        <br />
        j) Any other purpose disclosed during the course of providing services.
        <br />
        <br />
        <h2 className="text-xl bold">
          4. Does 500Chow share my Information with others?
        </h2>
        <br />
        We will not share your information without your express permission.
        Certain personal information may be shared with affiliates, partners,
        and service providers to deliver services and comply with legal or
        regulatory requirements.
        <br />
        <br />
        <h2 className="text-xl bold">
          5. How do we protect your Personal Information?
        </h2>
        <br />
        500Chow is dedicated to safeguarding the information we collect. We
        maintain appropriate administrative, technical, and physical safeguards,
        including firewalls and data encryption, to prevent unauthorized access
        or use of your information.
        <br />
        <br />
        <h2 className="text-xl bold">
          6. Who is responsible for protecting your password?
        </h2>
        <br />
        You are solely responsible for protecting your password. Do not share it
        with anyone. 500Chow will never request your password via email, SMS, or
        any other means. If you believe your password is compromised, change it
        immediately and contact us.
        <br />
        <br />
        <h2 className="text-xl bold">
          7. How can you prevent the use of Personal Information?
        </h2>
        <br />
        To stop receiving marketing messages or modify email preferences: -
        Follow opt-out messages in emails.
        <br />
        - Use the notification switch-off process in the application settings.
        <br />
        - Contact us at any time using the provided contact details. Opting out
        of marketing messages does not affect the processing of Personal
        Information for legitimate service-related purposes.
        <br />
        <br />
        <h2 className="text-xl bold">
          8. What happens when you do not provide necessary Personal
          Information?
        </h2>
        <br />
        Failure to provide required Personal Information, when needed to comply
        with the law or perform contractual obligations, may result in our
        inability to provide certain services.
        <br />
        <br />
        <h2 className="text-xl bold">
          9. How long do we store your Personal Data?
        </h2>
        <br />
        We retain your information for as long as your account is active or as
        required by law for regulatory purposes. This includes post-deactivation
        periods needed for audits, legal compliance, dispute resolution, and
        agreement enforcement.
        <br />
        <br />
        <h2 className="text-xl bold">
          10. Does our Privacy Policy apply to services from Links to Other
          Websites?
        </h2>
        <br />
        This Privacy Policy applies solely to 500Chow's services. Links to other
        websites within our services are not operated or controlled by 500Chow.
        We are not responsible for the content or policies of such websites.
        <br />
        <br />
        <h2 className="text-xl bold">11. Changes to this Privacy Policy</h2>
        <br />
        500Chow may change its Privacy Policy at its sole discretion, with
        notifications for any updates. Your continued use of the application or
        website after changes implies agreement with the new policy.
        <br />
        <br />
        <h2 className="text-xl bold">12. How to Contact Us</h2>
        <br />
        For questions about our practices or this Privacy Policy, contact us at
        +2349021077210 and info@500chow.com.
        <br />
        <br />
        <h2 className="text-xl bold">Last Updated: November, 2023</h2>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
