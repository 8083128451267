import {
  bigMamaTweet,
  bottomBanner1,
  bottomBanner2,
  bottomBanner3,
  bottomBanner4,
  bottomBanner5,
  carousel1,
  carousel2,
  carousel3,
  carousel4,
  chigozieTweet,
  damilolaTweet,
  metafiosoTweet,
  temiTweet,
} from '../assets/png';
import { facebook, instagram, linkedin, tiktok, twitter } from '../assets/svg';

export const faqs = [
  {
    title: 'What is ChowCentral?',
    content: 'ChowCentral is building the largest restaurant chain in Africa.',
  },
  {
    title: 'Which locations do we currently deliver to?',
    content:
      'Our delivery services currently encompass various locations across the island, such as Lekki, Ikoyi, Ajah, and Victoria Island. Additionally, for our customers residing on the mainland, we have established a dedicated branch in Surulere to cater to that area.',
  },
  {
    title: 'How do I create an account on ChowCentral?',
    content:
      "To get started, we kindly request you to download the ChowCentral app from either the Google Play Store or the App Store. Once installed, simply input your personal information during the sign-up process, and voila! You'll be all set to enjoy our services.",
  },
  {
    title: 'How do I fund/top up me ChowCentral wallet?',
    content:
      "To access your wallet, simply go to your profile and click on the 'Wallet' tab. Then, select the 'Fund Wallet' option, and a list of available funding methods will be displayed. Choose the option that suits you best. Afterward, enter the desired amount you wish to add to your wallet and click on your preferred funding option to proceed",
  },
  {
    title: 'When can I expect my wallet to be credited?',
    content:
      "Once you've processed payment to fund your wallet, it will be created in minutes. If you experience any issues, please reach out to our support team. hello@500chow.com",
  },
  {
    title:
      'In the event that my payment fails to go through, what steps can be taken to resolve the issue?',
    content:
      "If you encounter payment issues, we recommend refreshing your app first. Should the problem persist, please don't hesitate to contact our customer support for further assistance.",
  },
  {
    title: 'What is the expected timeframe for receiving my refund?',
    content:
      'Your refund will be processed and issued within a timeframe of 24 to 48 hours.',
  },
  {
    title: 'How long does delivery take?',
    content:
      'Our delivery service aims to bring your order to you within an estimated time frame of 30-40 minutes. However, please note that the actual delivery time may vary based on the address you have provided. In some cases, deliveries can even arrive earlier than the estimated time. We strive to ensure prompt and efficient service to meet your expectations',
  },
  {
    title: 'Is there a way to update my phone number on my profile?',
    content:
      'Reach out to our customer support team who can guide you through the process and assist with requesting a change of your mobile number.',
  },
  {
    title: 'Where can I direct my feedback or leave a review?',
    content:
      'For any inquiries or feedback, please feel free to contact our customer care team. Additionally, you can also share your reviews and feedback on our various social media platforms. We value your input and look forward to hearing from you!',
  },
];

export const carouselCopies = [
  {
    title: 'Ordering made seamless!',
    content:
      'Order your favorite meals from ChowCentral, check out from multiple restaurants, and enjoy the convenience of having all your meals delivered in a single delivery within minutes. Swift and easy!',
    image: carousel1,
    bg: 'bg-cream2',
    imageStyle: 'w-7/12 float-right',
  },
  {
    title: 'Payment made easier!',
    content:
      'Pay for your meals by funding your ChowCentral wallet on the app. Use the wallet balance to conveniently cover your orders, ensuring a seamless payment experience.',
    image: carousel2,
    bg: 'bg-chow-orange',
    imageStyle: 'w-5/12 mx-auto',
    style: 'secondary',
    gradient: 'orange-carousel-gradient',
  },
];

export const carouselCopies2 = [
  {
    title: 'Real Time Food Tracking',
    content:
      'Track your meal from order to delivery with our seamless food delivery service. Get real-time updates and estimated delivery times on the ChowCentral app.',
    image: carousel3,
    bg: 'bg-chow-green',
    imageStyle: 'w-5/12 mx-auto',
    style: 'secondary',
    gradient: 'green-carousel-gradient',
    textStyles: 'text-white',
  },
  {
    title: 'Get Delicious Offers',
    content:
      "Discover the latest deals and offers from your favorite restaurants, whether you're at home, work, or enjoying time with friends. Planning a special date-night dinner? Stay ahead by downloading the ChowCentral app and explore a world of flavorful delights!",
    image: carousel4,
    bg: 'bg-chow-black',
    imageStyle: 'w-8/12 float-right',
    textStyles: 'text-white',
  },
];

export const preFooterCopies = {
  1: {
    title: 'Get delicious food delivered to you in minutes every day',
    image: bottomBanner1,
  },
  2: {
    title: 'Diverse meal options to satisfy all your cravings',
    image: bottomBanner2,
  },
  3: {
    title: 'Best quality and healthy meals for your daily wellbeing',
    image: bottomBanner3,
  },
  4: {
    title: 'Enjoy easy payments and transactions for your convenience',
    image: bottomBanner4,
  },
  5: {
    title: 'Fast delivery to your doorstep',
    image: bottomBanner5,
  },
};

export const blog = 'https://500chow.medium.com';

export const socials = [facebook, twitter, instagram, linkedin, tiktok];

export const tweets = [
  {
    displayName: 'Damilola🧘‍♀️',
    username: '@timidyorubagirl',
    created: '3:45 AM · May 28, 2023',
    tweet: 'The turkey they gave me was hugeee. It’s chow central.',
    profilePicture: damilolaTweet,
  },
  {
    displayName: 'big mama',
    username: '@kanyeen___',
    created: '8:57 PM · Jul 12, 2023',
    tweet: 'Affordable and delicious food. Delivery sef cheap',
    profilePicture: bigMamaTweet,
  },
  {
    displayName: 'Podcast link in bio ❤️.',
    username: '@fancy_temi',
    created: '6:52 PM · Aug 7, 2023',
    tweet: `Have you tried jollof rice from 500 chow? 

    It’s so good 😍😍😍😍😍😍. I’m having a good time rn I swear
    `,
    profilePicture: temiTweet,
  },
  {
    displayName: 'Chigozie Obi',
    username: '@ChigozieObi_',
    created: '3:21 AM · May 28, 2023',
    tweet: `Hm I bought rice and chicken from one vendor (chow something, can’t remember) at hertitude and the size of the chicken was like my head ahn ahn`,
    profilePicture: chigozieTweet,
  },
  {
    displayName: 'capo di tutti',
    username: '@Metafioso',
    created: '2:28 AM · May 21, 2023',
    tweet: `fucking hell. I swear by 500Chow Lekki jollof. It’s the best jollof I’ve had since the one a twitter vendor sold to me in 2021. 

    If you think good food can’t keep a man. I have news for you`,
    profilePicture: metafiosoTweet,
  },
  {
    displayName: 'big mama',
    username: '@kanyeen___',
    created: '8:56 PM · Jul 12, 2023',
    tweet: `You guys are sleeping on chow central`,
    profilePicture: bigMamaTweet,
  },
];
