import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  closeMenu,
  deliverySticker,
  demandSticker,
  menu,
  redLogo,
  sticker8,
  whiteCaret,
  whiteLogo,
} from "../../assets/svg";
import { IBrand } from "../../types/app.types";
import React from "react";
import { blog } from "../../constants";

export const Nav = ({ brands }: { brands: IBrand[] }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const redLogoRoutes = ["/company"];

  const [navOpen, setNavOpen] = React.useState(false);

  React.useEffect(() => {
    navOpen &&
      window.innerWidth < 1024 &&
      document.body.classList.add("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [navOpen]);

  return (
    <>
      <div
        className={`inset-0 bg-white lg:hidden fixed z-50 transition-element2 ${
          navOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {/* mobile nav links */}
        <div className="m-2.5 sm:m-5 bg-cream2 rounded-[10px] sm:rounded-[20px] h-[calc(100%-20px)] sm:h-[calc(100%-40px)] overflow-y-auto flex flex-col justify-between">
          <div>
            <div
              className={`flex items-center justify-between py-4 px-4 sm:px-12`}
            >
              <NavLink to="/" onClick={() => setNavOpen((prev) => !prev)}>
                <img
                  src={
                    (redLogoRoutes.includes(pathname) || navOpen) &&
                    window.innerWidth < 1024
                      ? redLogo
                      : whiteLogo
                  }
                  alt="logo"
                  className="lg:h-[40px] h-[63px]"
                />
              </NavLink>
              <button
                className="lg:hidden"
                onClick={() => setNavOpen((prev) => !prev)}
              >
                {navOpen && <img src={closeMenu} alt="menu" />}
              </button>
            </div>

            <div className="mt-10 lg:hidden px-8">
              <div className="py-7 border-t border-b border-black border-opacity-25 ">
                <NavLink
                  to="/company"
                  onClick={() => setNavOpen((prev) => !prev)}
                >
                  <p>Company</p>
                </NavLink>
              </div>
              <div className="border-b border-black border-opacity-25 py-7">
                <p className="text-xs text-chow-red">Brands</p>
                {brands.map((brand) => (
                  <NavLink
                    className="mt-7 block"
                    key={brand.id}
                    to={`/brand/${brand.slug}`}
                    onClick={() => setNavOpen((prev) => !prev)}
                  >
                    <p>{brand.name}</p>
                  </NavLink>
                ))}
              </div>
              <div className="border-b border-black border-opacity-25 py-7">
                <NavLink to="/faqs" onClick={() => setNavOpen((prev) => !prev)}>
                  <p>FAQs</p>
                </NavLink>
              </div>
              <div className="border-b border-black border-opacity-25 py-7">
                <NavLink
                  target="_blank"
                  to={blog}
                  onClick={() => setNavOpen((prev) => !prev)}
                >
                  <p>Blog</p>
                </NavLink>
              </div>
              <div className="border-b border-black border-opacity-25 py-7">
                <p className="text-xs text-chow-red">Contact us</p>
                <a className="mt-7 block" href="tel:+2349021077210">
                  <p>+234 902 107 7210</p>
                </a>
                <a className="mt-7 block" href="mailto:hello@chowcentral.com">
                  <p>hello@chowcentral.com</p>
                </a>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center mt-5">
            <img
              src={deliverySticker}
              alt="delivery-sticker"
              className="w-[150px] relative z-10"
            />
            <img
              src={demandSticker}
              alt="demand-sticker"
              className="w-[150px] -ml-10"
            />
            <img src={sticker8} alt="sticker8" className="w-[100px] -ml-12" />
          </div>
        </div>
      </div>

      <nav
        className={`absolute lg:fixed lg:flex items-center justify-between py-4 px-4 sm:px-12 lg:bg-brown-600 rounded-[10px] sm:rounded-[20px] z-40 right-2.5 left-2.5 sm:right-5 sm:left-5 top-2.5 sm:top-5`}
      >
        <div className={`flex items-center justify-between`}>
          <NavLink to="/">
            <img
              src={
                (redLogoRoutes.includes(pathname) || navOpen) &&
                window.innerWidth < 1024
                  ? redLogo
                  : whiteLogo
              }
              alt="logo"
              className="lg:h-[40px] h-[63px]"
            />
          </NavLink>
          {!navOpen && (
            <button
              className="lg:hidden"
              onClick={() => setNavOpen((prev) => !prev)}
            >
              <img src={menu} alt="menu" />
            </button>
          )}
        </div>

        {/* web nav links */}
        <>
          <div className="text-white space-x-[75px] hidden lg:flex">
            <NavLink to="/company">
              <p>Company</p>
            </NavLink>
            <div className="relative w-[60px]">
              <div className="absolute h-[60px] w-full inline-flex space-x-1.5 items-center group -bottom-[18px]">
                <p className="cursor-pointer">Brands</p>
                <img
                  src={whiteCaret}
                  alt="caret"
                  className="group-hover:rotate-180 transition-element"
                />
                <div className="absolute top-12 hidden group-hover:block">
                  <NavWithChild
                    links={brands.map((brand) => {
                      return {
                        title: brand.name,
                        onClick: () => navigate(`/brand/${brand.slug}`),
                      };
                    })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-white space-x-[75px] hidden lg:flex items-center">
            <div className="relative w-[100px]">
              <div className="absolute h-[60px] w-full inline-flex space-x-1.5 items-center group -top-[30px]">
                <p className="cursor-pointer">Contact us</p>
                <img
                  src={whiteCaret}
                  alt="caret"
                  className="group-hover:rotate-180 transition-element"
                />
                <div className="absolute top-12 hidden group-hover:block">
                  <NavWithChild
                    links={[
                      {
                        title: "+234 902 107 7210",
                        onClick: () =>
                          (window.location.href = "tel:+2349021077210"),
                      },
                      {
                        title: "hello@chowcentral.com",
                        onClick: () =>
                          (window.location.href =
                            "mailto:hello@chowcentral.com"),
                      },
                    ]}
                    className="w-[220px]"
                  />
                </div>
              </div>
            </div>
            <NavLink to="/faqs">
              <p>FAQs</p>
            </NavLink>
            <NavLink target="_blank" to={blog}>
              <p>Blog</p>
            </NavLink>
          </div>
        </>
      </nav>
    </>
  );
};

const NavWithChild = ({
  links,
  className,
}: {
  links: { title: string; onClick: () => void }[];
  className?: string;
}) => {
  return (
    <div
      className={`rounded-[10px] backdrop-blur-md bg-grey8 bg-opacity-80 p-[15px] shadow-nav-child w-[120px] ${className}`}
    >
      {links.map(({ title, onClick }, index) => (
        <div key={title}>
          <button
            className="focus:outline-none text-black transition-element text-start"
            onMouseEnter={(e) => e.currentTarget.classList.add("bold")}
            onMouseLeave={(e) => e.currentTarget.classList.remove("bold")}
            onClick={onClick}
          >
            {title}
          </button>
          {links.length - 1 !== index && (
            <div className="border-b border-black border-opacity-25 my-[15px]" />
          )}
        </div>
      ))}
    </div>
  );
};
