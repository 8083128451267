import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { add, remove, twitter } from "../../assets/svg";
import { downloadApp } from "../../helpers";
import { PrimaryButton } from "./buttons";

// brand card
interface IBrandCard {
  bannerUrl?: string;
  name: string;
  logoUrl?: string;
  description?: string;
  slug?: string;
}
export const BrandCard: FC<IBrandCard> = ({ bannerUrl, name, slug }) => {
  const navigate = useNavigate();
  const locations = ["Lekki", "Oniru", "VI", "Surulere", "Ajah", "Yaba"];

  const toBrand = () => navigate(`/brand/${slug}`);

  return (
    <>
      <div
        className="rounded-[20px] overflow-hidden w-full group relative cursor-pointer"
        onClick={toBrand}
      >
        <div className="bg-black bg-opacity-0 absolute w-full h-full lg:group-hover:bg-opacity-50 transition-element2 z-10" />

        <div
          className={`relative h-[500px] ${bannerUrl ? "" : "bg-primary-200"}`}
        >
          {bannerUrl ? (
            <img
              src={bannerUrl}
              alt="background"
              className="object-cover h-full w-full object-center transition-element2 lg:group-hover:scale-125"
            />
          ) : (
            <p className="text-2xl flex items-center justify-center pt-20 semibold">
              {name}
            </p>
          )}
        </div>

        <div className="z-20 px-7 absolute bottom-7">
          <p className="sm:text-3xl bold py-3 sm:py-5 p-5 bg-white rounded-full inline-flex">
            {name}
          </p>
          <div className="mt-4 items-center hidden lg:group-hover:flex transition-element2">
            <p className="mr-2.5 medium text-lg text-white">Available in:</p>
            {locations.map((location) => (
              <div
                key={location}
                className="px-3.5 py-2.5 text-sm bg-cream2 mr-2.5 text-black rounded-full"
              >
                {location}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-3 items-center flex justify-center lg:hidden">
        <p className="mr-1.5 medium text-xs">Available in</p>
        {locations.map((location) => (
          <div
            key={location}
            className="px-2 py-1 text-[9px] bg-cream2 mr-1 text-black rounded-full"
          >
            {location}
          </div>
        ))}
      </div>
    </>
  );
};

// meal card
interface IMealCard {
  imageUrl?: string;
  name: string;
  description?: string;
}
export const MealCard: FC<IMealCard> = ({ imageUrl, name }) => {
  return (
    <div className="w-full sm:w-6/12 xl:w-4/12 mt-10 lg:flex justify-center">
      <div className="rounded-[16px] overflow-hidden sm:w-11/12 xl:w-11/12 mx-auto shadow-meal-card group">
        <div
          className={`h-56 overflow-hidden rounded-t-[16px] ${
            imageUrl ? "" : "bg-cream"
          }`}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="background"
              className="object-cover h-full w-full object-center group-hover:scale-105 transition-element2"
            />
          ) : (
            <p className="text-2xl flex items-center justify-center pt-24 semibold">
              {name}
            </p>
          )}
        </div>

        <div className="px-5 sm:px-7 pt-3 pb-5 flex items-center justify-between rounded-b-[16px] space-x-3">
          <p className="text-base sm:text-lg medium truncate">{name}</p>
          <PrimaryButton
            onClick={downloadApp}
            text="Order now"
            classNames="flex-shrink-0"
          />
        </div>
      </div>
    </div>
  );
};

// testimonial
interface ITestimonial {
  avatar: string;
  name: string;
  username: string;
  content: string;
  index: number;
}

export const Testimonial: FC<ITestimonial> = ({
  avatar,
  name,
  username,
  content,
  index,
}) => {
  return (
    <div
      className={`p-10 sm:w-6/12 lg:w-4/12 ${
        index % 2 === 1 ? "bg-white" : "bg-grey5"
      }`}
    >
      <p className="leading-[32px] text-dark2 text-lg">{content}</p>
      <div className="mt-14 flex items-center space-x-4">
        <img
          src={avatar}
          alt="avatar"
          className="rounded-full h-12 w-12 object-cover"
        />
        <div className="">
          <p className="text-dark2 medium">{name}</p>
          <p className="text-grey2 text-sm">@{username}</p>
        </div>
      </div>
    </div>
  );
};

// faq
interface IFaq {
  title: string;
  content: string;
}
export const FaqCard: FC<IFaq> = ({ title, content }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="py-10">
      <div
        className={` ${
          show ? "p-7 rounded-[16px] bg-cream2 bg-opacity-30" : ""
        }`}
      >
        <button
          className="flex items-center justify-between w-full focus:outline-none"
          onClick={() => setShow((prev) => !prev)}
        >
          <p className="text-dark4 text-left text-sm sm:text-2xl medium">
            {title}
          </p>
          <img src={show ? remove : add} alt="plus" />
        </button>
        {show && (
          <p className="mt-5 text-opacity-75 text-sm sm:text-lg">{content}</p>
        )}
      </div>
    </div>
  );
};

interface ICarouselCard {
  bg: string;
  image: string;
  title: string;
  content: string;
  imageStyle: string;
  style?: string;
  gradient?: string;
  textStyles?: string;
}

export const CarouselCard: FC<ICarouselCard> = ({
  bg,
  image,
  title,
  content,
  imageStyle,
  style = "primary",
  gradient,
  textStyles,
}) => {
  return (
    <div
      className={`rounded-[20px] h-[375px] sm:h-[700px] overflow-hidden w-full group relative panel ${bg}`}
    >
      <div
        className={`absolute ${
          style === "primary"
            ? "top-0 right-0"
            : "top-12 flex justify-center left-0"
        }`}
      >
        <img src={image} alt="icon" className={imageStyle} />
      </div>

      <div
        className={`absolute z-30 bottom-7 sm:bottom-28 left-8 sm:left-20 w-9/12 sm:w-7/12 ${textStyles}`}
      >
        <p className="text-2xl sm:text-5xl medium">{title}</p>
        <p className="mt-3.5 text-xs sm:text-lg">{content}</p>
      </div>

      {style === "secondary" && gradient && (
        <div
          className={`h-[220px] sm:h-[520px] absolute z-20 bottom-0 left-0 w-full ${gradient}`}
        />
      )}
    </div>
  );
};

export const TwitterCard = ({ ...tweet }) => {
  return (
    <div className="rounded-xl p-4 sm:p-7 shadow-meal-card bg-white mr-7 flex-shrink-0 w-[200px] sm:w-[410px] text-[8px] sm:text-base">
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="h-[28px] w-[28px] sm:h-[55px] sm:w-[55px] rounded-full overflow-hidden bg-slate-500 mr-2 sm:mr-3.5">
            <img src={tweet.profilePicture} alt="profile" />
          </div>
          <div>
            <p className="sf-bold text-dark5">{tweet.displayName}</p>
            <p className="sf text-grey9">
              {tweet.username} · {tweet.created}
            </p>
          </div>
        </div>
        <img src={twitter} alt="twitter" className="w-3 sm:w-auto" />
      </div>

      <p className="mt-5 sf text-dark5">{tweet.tweet}</p>
    </div>
  );
};
