import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Loader } from "../components/loader";

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface IEmailComplete {
  success: boolean;
  email: string;
  phoneVerified: boolean;
}

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const id = searchParams.get("id");

  const [response, setResponse] = useState<IEmailComplete>(
    {} as IEmailComplete
  );

  const verifyEmail = useCallback(async () => {
    await fetch(`${BASE_URL}/verify/email/complete/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, id }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => setResponse(data.data))
      .catch(() => {});
  }, [token, id]);

  useEffect(() => {
    verifyEmail();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="h-screen bg-grey6 sm:py-12 lg:py-32 flex flex-col items-center justify-center">
        {response === null ? (
          <>
            <Loader />
            <p>We're verifying your email</p>
          </>
        ) : response?.success === true ? (
          <>
            <p className="text-lg semibold">Email is verified!</p>
            <p>You can close this page</p>
          </>
        ) : (
          <>
            <p className="text-lg semibold">Verification Failed</p>
            <p className="">Invalid or expired token</p>
          </>
        )}
      </div>
    </>
  );
};

export default VerifyEmail;
