import { useEffect, useState } from "react";
import { appStore, bottomBannerOverlay, playStore } from "../../assets/svg";
import { preFooterCopies } from "../../constants";

export const PreFooter = () => {
  const [active, setActive] = useState(1);

  useEffect(() => {
    let timer1 = setInterval(() => {
      setActive((prev) => (prev !== 5 ? prev + 1 : 1));
    }, 3000);

    return () => {
      clearInterval(timer1);
    };
  }, []);

  return (
    <>
      <div
        className={`pt-10 px-8 xl:px-44 flex flex-col lg:flex-row items-center justify-between relative mx-2.5 sm:mx-5 rounded-[20px] transition-element overflow-hidden ${
          active % 2 === 0 ? "bg-chow-green" : "bg-cream2"
        }`}
      >
        <img
          src={bottomBannerOverlay}
          alt="banner-overlay"
          className="absolute w-full h-full left-0 top-0 object-cover"
        />

        <div className="sm:w-8/12 lg:w-6/12 xl:w-4/12 relative z-10">
          <h2 className="text-2xl sm:text-4xl text-black semibold text-center lg:text-left">
            {preFooterCopies[active as keyof typeof preFooterCopies].title}
          </h2>
          <div className="flex items-center space-x-6 mt-10 justify-center lg:justify-start">
            <a href="https://chowcentral.onelink.me/bvtE/t8dfjd86">
              <button>
                <img src={appStore} alt="appStore" className="h-11" />
              </button>
            </a>
            <a href="https://chowcentral.onelink.me/bvtE/t8dfjd86">
              <button>
                <img src={playStore} alt="playStore" className="h-11" />
              </button>
            </a>
          </div>

          <div className="lg:flex items-center mt-16 hidden">
            <div
              className={`h-1 w-10 rounded-full transition-element2 mr-1.5 bg-white relative z-10`}
              style={{
                transform:
                  active !== 1
                    ? `translateX(${active * 45 - 40}px)`
                    : "translateX(0px)",
              }}
            />
            {[1, 2, 3, 4].map((number) => (
              <div
                key={number}
                className={`h-1 w-10 rounded-full transition-element2 mr-1.5 bg-black bg-opacity-25 ${
                  number < active ? "-translate-x-10" : ""
                }`}
              />
            ))}
          </div>
        </div>

        <div className="lg:w-4/12 mt-10 lg:mt-0 relative z-10">
          <img
            src={preFooterCopies[active as keyof typeof preFooterCopies].image}
            alt="phone"
            className="w-[300px]"
          />
        </div>
      </div>
    </>
  );
};
