import { leftPhone, middlePhone, rightPhone } from "../../assets/png";
import {
  appStore,
  craveSticker,
  deliverySticker,
  demandSticker,
  heroMotif,
  heroMotif2,
  playStore,
  rotateSticker,
  sticker11,
  sticker8,
} from "../../assets/svg";

export const Hero = () => {
  return (
    <>
      <div className="relative mt-2.5 sm:mt-5 lg:mt-28 bg-chow-red mx-2.5 sm:mx-5 rounded-[10px] sm:rounded-[20px] overflow-hidden">
        <img src={heroMotif} className="absolute right-0 w-full" alt="motif" />
        <img
          src={heroMotif2}
          className="absolute bottom-0 right-0 w-full"
          alt="motif"
        />

        <div className="relative z-10">
          <h1 className="bold text-3xl sm:text-5xl text-white w-11/12 lg:w-6/12 text-center mx-auto mt-[160px] sm:mt-[120px]">
            Hungry? Get the food you want from the brands you love.
          </h1>
          <p className="text-white text-center text-lg sm:text-2xl mt-7">
            Tasty meals delivered in minutes
          </p>
          <div className="flex justify-center items-center space-x-2 mt-8">
            <a
              rel="noreferrer"
              target={"_blank"}
              href="https://chowcentral.onelink.me/bvtE/t8dfjd86"
            >
              <button>
                <img src={playStore} alt="playStore" className="h-10" />
              </button>
            </a>
            <a
              rel="noreferrer"
              target={"_blank"}
              href="https://chowcentral.onelink.me/bvtE/t8dfjd86"
            >
              <button>
                <img src={appStore} alt="appStore" className="h-10" />
              </button>
            </a>
          </div>

          <div className="flex justify-center relative h-[420px]">
            <img
              src={deliverySticker}
              alt="delivery-sticker"
              className="w-20 sm:w-32 lg:w-[250px] absolute left-0 -bottom-2 sm:-bottom-16 z-10"
            />
            <img
              src={sticker8}
              alt="sticker8"
              className="w-20 sm:w-32 lg:w-[250px] absolute left-10 sm:left-60 bottom-3 sm:-bottom-16"
            />
            <img
              src={demandSticker}
              alt="demand-sticker"
              className="w-20 sm:w-32 lg:w-[250px] absolute left-6 sm:left-32 bottom-10 sm:-bottom-20 lg:bottom-10 z-10"
            />

            <div className="inline-flex justify-center group absolute -bottom-32 sm:-bottom-80">
              <img
                src={leftPhone}
                alt="phone"
                className="w-[230px] mt-10 translate-x-10 group-hover:-translate-y-16 group-hover:-rotate-[5deg] transition duration-500 hidden sm:block relative z-10"
              />
              <img
                src={middlePhone}
                alt="phone"
                className="w-[230px] sm:w-[295px] group-hover:-translate-y-16 transition duration-500 relative z-20"
              />
              <img
                src={rightPhone}
                alt="phone"
                className="w-[230px] mt-10 -translate-x-10 group-hover:-translate-y-16 group-hover:rotate-[5deg] transition duration-500 hidden sm:block relative z-10"
              />
            </div>

            <img
              src={craveSticker}
              alt="travel-sticker"
              className="w-20 sm:w-32 lg:w-[250px] absolute right-20 sm:right-72 -bottom-5 sm:bottom-0 z-10 sm:-z-10"
            />
            <img
              src={sticker11}
              alt="sticker-11"
              className="w-28 sm:w-32 lg:w-[250px] absolute right-0 xl:right-32 bottom-0 sm:-bottom-5"
            />
            <img
              src={rotateSticker}
              alt="rotate-sticker"
              className="w-16 sm:w-32 lg:w-[200px] absolute right-12 sm:right-40 xl:right-52 xl:bottom-20 top-14 sm:top-20 lg:top-auto z-20 rotating-element"
            />
          </div>
        </div>
      </div>
    </>
  );
};
