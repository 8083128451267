import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Features from "./views/features";

function App() {
  return (
    <div>
      <Features />
      <ToastContainer
        toastClassName={() =>
          "relative flex p-3 text-sm min-h-10 rounded-md overflow-hidden mb-4 bg-blue-300"
        }
        position="top-right"
        autoClose={3000}
        hideProgressBar
      />
    </div>
  );
}

export default App;
