import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "../../hooks/scroll";
import Brand from "./brand";
import Download from "./download";
import Home from "./home";
import PrivacyPolicy from "./privacy-policy";
import ResetPassword from "./reset-password";
import TermsConditions from "./terms-and-conditions";
import VerifyEmail from "./verify-email";
import DeleteRequest from "./delete-account";
import FAQs from "./faqs";
import Company from "./company";
import { Nav } from "../components/nav";
import { Footer } from "../components";
import { IBrand } from "../../types/app.types";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Features = () => {
  const [brands, setBrands] = React.useState<IBrand[]>([]);

  const getBrands = async () => {
    await fetch(`${BASE_URL}/brand/all/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => setBrands(data.data))
      .catch(() => {});
  };

  React.useEffect(() => {
    brands.length === 0 && getBrands();
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<>loading..</>}>
        <Nav brands={brands} />

        <Routes>
          <Route path="*" element={<Home brands={brands} />} />
          <Route path="/reset-password/*" element={<ResetPassword />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/company" element={<Company />} />
          <Route path="/brand/:slug" element={<Brand />} />
          <Route path="/verify-email/" element={<VerifyEmail />} />
          <Route path="/download/" element={<Download />} />
          <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions/" element={<TermsConditions />} />
          <Route path="/delete-request" element={<DeleteRequest />} />
        </Routes>

        <Footer brands={brands} />
      </Suspense>
    </BrowserRouter>
  );
};

export default Features;
